import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="social-media-float">
        <a href="/contact"><i className='far fa-comment-alt'></i>Contact Us</a>
        <a href="tel:+919226075291" target="_blank"><i className="fa fa-phone"></i>Call Us</a>
        <a href="https://wa.me/919226075291" target="_blank"><i className="fab fa-whatsapp"></i>Whatsapp</a>
      </div>
      <div className="footer-container">
        <div className="footer-section">
          <h3>About Us</h3>
          <p>We are transforming the Skyline, Redefining Modern Living by Creating Iconic Global and Stunning Landmarks Designed for today’s Vibrant Community in Pune.</p>
        </div>
        <div className="footer-section">
          <h3>Quick Links</h3>
          <ul>
            <li><a href="/about">About Us</a></li>
            <li><a href="/projects">Projects</a></li>
            <li><a href="/contact">Contact Us</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>Contact Us</h3>
          <ul>
            <li><a href="https://maps.app.goo.gl/PehbcnJQTa2FcrRR8" target="_blank"><i className="fa fa-map-marker"></i> 5th Floor, Shantai Corner, Oppo. SB Patil School, Maske Vasti, Ravet, Pune - 412101</a></li>
            <li><a href="mailto:info@vtabuildcon.com" target="_blank"><i className='fas fa-envelope'></i> info@vtabuildcon.com</a></li>
            <li><a href="tel:+919226075291" target="_blank"><i className="fa fa-phone"></i> +91 92260 75291</a></li>
          </ul>
        </div>
      </div>
      <p>&copy; All rights reserved <a href="http://vtabuildcon.com/">VTA Buildcon</a>, Designed by <a href="http://amoldevzone.in" target="_blank">MADCoders</a>.</p>
    </footer>
  );
};

export default Footer;
