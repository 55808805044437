import React from 'react';
import './css/Commercial.css';

import DivineVastu from './assets/image/building/divine_vastu.avif'
import Drishti from './assets/image/building/drishti_developers.avif'
import ShantaiGreen2 from './assets/image/building/shantai_green_2.avif'
import IBMDevelopers from './assets/image/building/ibm_developers.avif'

import ResidentialBanner from './assets/image/resi-bg.avif'

function ResUpcoming() {
    return (
        <div>
            {/* About Us Banner Start */}
            <div className='about-banner'>
                <img width={'100%'} height={'100%'} src={ResidentialBanner} />
                <div className='banner-path'>
                    <h1>Ongoing Projects</h1>
                    <p><a href='/home'>Home</a> &#8594; <a href='/projects'>Projects</a> &#8594; Ongoing Projects</p>
                </div>
            </div>
            {/* About Us Banner End */}

            {/* Ongoing Projects */}
            <div className='project-status'>
                <h1>Ongoing Project</h1>
            </div>
            <div className='comm-projects'>
                <div className='projects'>
                    <a href='/projects/ongoing/divine_vastu' className='project-img project-card'>
                        <img width={'100%'} height={'100%'} src={DivineVastu} className='image project-image' />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>Shantai's Divine Vastu</h1>
                            <p>Punawale</p>
                        </div>
                    </a>
                    <a href='/projects/ongoing/shantai_green_2' className='project-img project-card'>
                        <img width={'100%'} height={'100%'} src={ShantaiGreen2} className='image project-image' />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>Shantai Green Phase 2</h1>
                            <p>Ravet</p>
                        </div>
                    </a>
                    <a href='/projects/ongoing/drishti' className='project-img project-card'>
                        <img width={'100%'} height={'100%'} src={Drishti} className='image project-image' />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>Drishti Developers</h1>
                            <p>Ravet</p>
                        </div>
                    </a>
                    <a href='/projects/ongoing/ibm_developer' className='project-img project-card'>
                        <img width={'100%'} height={'100%'} src={IBMDevelopers} className='image project-image' />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>IBM Developer</h1>
                            <p>Chikhali</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default ResUpcoming;
