import React, { useState, useEffect } from "react";
import {
    Nav,
    NavLogoContainer,
    Logo,
    CompanyName,
    NavLink,
    Bars,
    NavMenu,
    DropdownHover,
    DropdownContent,
} from "./navbarElements";

import LogoImage from '../assets/vta-logo.avif';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            // If scrolling down, hide the navbar; if scrolling up, show it
            if (currentScrollY > lastScrollY) {
                setIsOpen(false); // Hide navbar
            }
            setLastScrollY(currentScrollY);
        };

        // Add the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up event listener on unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]);

    return (
        <>
            <Nav>
                <NavLogoContainer>
                    <Logo src={LogoImage} alt="Logo" width={'60px'} height={'60px'} />
                    <CompanyName>VTA Buildcon</CompanyName>
                </NavLogoContainer>
                <Bars onClick={toggleMenu} />
                <NavMenu isOpen={isOpen}>
                    <NavLink to="/home" onClick={closeMenu} >Home</NavLink>
                    <NavLink to="/about" onClick={closeMenu} >About</NavLink>
                    <DropdownHover>
                        <NavLink to="/projects" activeStyle onClick={closeMenu}>Projects</NavLink>
                        <DropdownContent>
                            <NavLink to="/projects/ongoing" activeStyle onClick={closeMenu}>Ongoing</NavLink>
                            <NavLink to="/projects/completed" activeStyle onClick={closeMenu}>Completed</NavLink>
                            <NavLink to="/projects/upcoming" activeStyle onClick={closeMenu}>Upcoming</NavLink>
                        </DropdownContent>
                    </DropdownHover>
                    <NavLink to="/contact" activeStyle onClick={closeMenu}>Contact</NavLink>
                </NavMenu>
            </Nav>
        </>
    );
};

export default Navbar;